import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Adjust, ScheduleSend, AccessTime, LibraryBooks, Add, Close, Delete, Edit } from '@mui/icons-material';
import { Order } from '../hooks/Interfaces';
import { SendMessageFunction } from '../../../../../websocket/hooks/types';
import LogProduction from '../../../../../utils/globalComps/LogProduction/LogProduction';
import { getOrderData, logProduction } from '../OrderApi';
import { ALL } from '../../../../../utils/globalComps/ProductionLog/ProductionLogHelpers'
import ProductionLogModal from '../../../../../utils/globalComps/ProductionLog/ProductionLogModal';
import ScheduleToMachine from '../../../../../utils/modals/ScheduleToMachine';
import ScheduleToLineplan from '../../../../../utils/globalComps/ScheduleToLineplan/ScheduleToLineplan';
import SetupsheetViewHelper from '../../setupsheets/SetupsheetViewHelper';
import { useOrdersContext } from '../context/OrdersContext';
import { scheduleToMachine } from '../../../scheduling/MachineScheduling/MachineSchedulingApi';
import OrderForm from '../OrderForm';

const TYPES = ['Molding', 'Secondary', 'Foaming'];

interface RowActionsProps {
	order: Order;
	sendMessage: SendMessageFunction;
}

const RowActions: React.FC<RowActionsProps> = ({ order, sendMessage }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [logProductionItems, setLogProductionItems] = useState<any[]>([]);
	const [logProductionType, setLogProductionType] = useState<number>(0);

	const [orderFormOpen, setOrderFormOpen] = useState(false);
	const [currentOrderId, setCurrentOrderId] = useState<number | undefined>(undefined);
	const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false);
	const [productionLogsOrderId, setProductionLogsOrderId] = useState<number | undefined>(undefined);

	const [scheduleToMachineOrderItem, setScheduleToMachineOrderItem] = useState<number | undefined>(undefined);
	const [scheduleToLineplanOrderItem, setScheduleToLineplanOrderItem] = useState<number | undefined>(undefined);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State to manage delete confirmation dialog

	const { machines, scrapCodes, items, permissions } = useOrdersContext()

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// Order Form
	const handleOrderFormOpen = () => {
		setCurrentOrderId(order.id);
		setOrderFormOpen(true);
	};

	const handleOrderFormClose = (submit: boolean) => {
		setOrderFormOpen(false);
		setCurrentOrderId(undefined);
		handleClose()
	};

	// LOG PRODUCTION
	const handleLogProductionClick = (type: number) => {
		setLogProductionType(type);
		getOrderData(order.id, type, (data: any) => {
			setLogProductionItems([data.object]);
		});
		handleClose();
	};

	const doLogProduction = (data: any) => {
		logProduction(logProductionType, data, (response: any) => {
			console.log('log production resp data :', response);
			if (response.status === 'success') {
				setLogProductionItems([]);
			}
			// Handle errors if needed
		});
	};

	const handleLogProductionClose = () => {
		setLogProductionItems([]);
		setLogProductionType(0);
		handleClose()
	};

	// Production Logs
	const openProductionLogs = (orderId: number) => {
		setProductionLogsOpen(true);
		setProductionLogsOrderId(orderId);
		handleClose()
	};

	const closeProductionLogs = () => {
		setProductionLogsOpen(false);
		setProductionLogsOrderId(undefined);
		handleClose()
	};

	// Scheduling to Machine
	const openScheduleToMachine = (orderId: number | undefined) => {
		setScheduleToMachineOrderItem(orderId);
	};

	const closeScheduleToMachine = () => {
		setScheduleToMachineOrderItem(undefined);
		handleClose()
	};

	const doScheduleToMachine = (orderId: number | undefined, machineId: number | undefined) => {
		scheduleToMachine(orderId, machineId, false, () => {
			// prompt the user to schedule to a lineplan after scheduling to a machine
			openScheduleToLineplan(orderId)
		})
		closeScheduleToMachine();
		handleClose()
	};

	// Scheduling to Lineplan
	const openScheduleToLineplan = (orderId: number | undefined) => {
		setScheduleToLineplanOrderItem(orderId);
	};

	const closeScheduleToLineplan = () => {
		setScheduleToLineplanOrderItem(undefined);
		handleClose()
	};

	// Delete Confirmation Dialog
	const handleDeleteClick = () => {
		setDeleteDialogOpen(true);
	};

	const handleDeleteConfirm = () => {
		sendMessage('delete_order', { order });
		setDeleteDialogOpen(false);
		handleClose();
	};

	const handleDeleteCancel = () => {
		setDeleteDialogOpen(false);
		handleClose()
	};

	return (
		<>
			<IconButton
				aria-label="manage"
				aria-controls="action-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<Adjust />
			</IconButton>
			<Menu
				id="action-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{ my: 0 }}
				slotProps={{
					paper: {
						sx: {
							borderRadius: 3,
							mt: 0.5,
						}
					}, 
				}}
				MenuListProps={{
					sx: {
						// py: 0
					}
				}}				
			>
				{/* EDIT */}
				{permissions.edit && 
				<MenuItem onClick={handleOrderFormOpen}>
					<ListItemIcon>
						<Edit />
					</ListItemIcon>
					<ListItemText primary="Edit" />
				</MenuItem>}
				<Dialog open={orderFormOpen} onClose={() => handleOrderFormClose(false)} maxWidth="md" fullWidth>
					<OrderForm
						id={currentOrderId}
						open={orderFormOpen}
						itemList={items}
						closeForm={handleOrderFormClose}
					/>
				</Dialog>
				{permissions.delete && 
				<MenuItem onClick={handleDeleteClick}>
					<ListItemIcon>
						<Delete />
					</ListItemIcon>
					<ListItemText primary="Delete" />
				</MenuItem>}
				<Divider />
				{order.can_schedule_machine && (
					<MenuItem onClick={() => openScheduleToMachine(order.id)}>
						<ListItemIcon>
							<ScheduleSend />
						</ListItemIcon>
						<ListItemText primary={order.scheduled_to_machine ? 'Re-Schedule to Machine' : 'Schedule to Machine'} />
					</MenuItem>
				)}
				{order.can_schedule_lineplan && (
					<MenuItem onClick={() => openScheduleToLineplan(order.id)}>
						<ListItemIcon>
							<ScheduleSend />
						</ListItemIcon>
						<ListItemText primary={order.scheduled_to_lineplan ? 'Re-Schedule to Lineplan' : 'Schedule to Lineplan'} />
					</MenuItem>
				)}
				{order.can_mold && (
					<MenuItem onClick={() => handleLogProductionClick(1)}>
						<ListItemIcon>
							<AccessTime />
						</ListItemIcon>
						<ListItemText primary="Log Production Molding" />
					</MenuItem>
				)}
				{order.can_secondary && (
					<MenuItem onClick={() => handleLogProductionClick(2)}>
						<ListItemIcon>
							<AccessTime />
						</ListItemIcon>
						<ListItemText primary="Log Production Secondary" />
					</MenuItem>
				)}
				{order.can_foam && (
					<MenuItem onClick={() => handleLogProductionClick(3)}>
						<ListItemIcon>
							<AccessTime />
						</ListItemIcon>
						<ListItemText primary="Log Production Foaming" />
					</MenuItem>
				)}
				<MenuItem onClick={() => openProductionLogs(order.id)}>
					<ListItemIcon>
						<LibraryBooks />
					</ListItemIcon>
					<ListItemText primary="Production Logs" />
				</MenuItem>
				{permissions.edit &&
				<MenuItem onClick={() => sendMessage('change_order_status', { order })}>
					<ListItemIcon>
						{order.type === 0 ? <Close /> : <Add />}
					</ListItemIcon>
					<ListItemText primary={order.type === 0 ? 'Close Order' : 'Open Order'} />
				</MenuItem>}
			</Menu>

			{/* Delete Confirmation Dialog */}
			<Dialog
				open={deleteDialogOpen}
				onClose={handleDeleteCancel}
				maxWidth={'xs'}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
				<DialogContent dividers>
					<Typography>Are you sure you want to delete Order {order.number}? This cannot be undone.</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color="error" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			{/* Log Production Modal */}
			<LogProduction
				data={logProductionItems}
				onLog={doLogProduction}
				onClose={handleLogProductionClose}
				typeName={TYPES[logProductionType - 1]}
				scrapCodeList={scrapCodes} // Replace with actual scrap code list
				nonConforming
				errors={[]} // Replace with actual errors if needed
			/>

			{/* Production Logs */}
			<ProductionLogModal
				open={productionLogsOpen}
				type={ALL}
				onClose={closeProductionLogs}
				orderId={productionLogsOrderId}
				allowTypeSelect
			/>

			{/* Scheduling */}
			<ScheduleToMachine
				orderItemId={scheduleToMachineOrderItem}
				closeHandler={closeScheduleToMachine}
				doSchedule={doScheduleToMachine}
				machineList={machines}
			/>
			<ScheduleToLineplan
				id={scheduleToLineplanOrderItem}
				closeHandler={closeScheduleToLineplan}
			/>

		</>
	);
};

export default RowActions;
