import React, { FC, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Box, Card } from '@mui/material/';
import { Routes, Route } from 'react-router-dom';
import { useEffectApi } from '../../utils/globals';
import { apiGet } from '../../utils/api';
import Dash from '../pages/dashboard/Dashboard';
import Shipping from '../pages/shipping/Shipping';
import MachineScheduling from '../pages/scheduling/MachineScheduling/MachineScheduling';
import LineplanScheduling from '../pages/scheduling/LineplanScheduling/LineplanScheduling';
import Molds from '../pages/management/molds/Molds';
import Items from '../pages/management/items/Items';
import Orders from '../pages/management/orders/Orders';
import NewOrders from '../pages/management/orders/NewOrders';
import BomItems from '../pages/management/bomitems/BomItems';
import Setupsheets from '../pages/management/setupsheets/Setupsheets';
import Accounts from '../pages/services/Accounts/Accounts';
import Factories from '../pages/services/Factories/Factories';
import Machines from '../pages/services/Machines/Machines';
import OrderSync from '../pages/services/order-sync/OrderSync';
import Permissions from '../pages/services/Permissions/Permissions';
import ArmLoading from '../pages/armloading/ArmLoading';
import Molding from '../pages/molding/Molding';
import SecondaryLineplan from '../pages/lineplan/Secondary';
import FoamingLineplan from '../pages/lineplan/Foaming';
import AssemblyLineplan from '../pages/lineplan/Assembly';
import Copyright from '../copyright/copyright';
import LinePlan from '../pages/services/LinePlan/LinePlan';
import MachinePlanning from '../pages/scheduling/MachinePlanning/MachinePlanning';
import Personnel from '../pages/management/personnel/Personnel';
import Statistics from '../pages/reporting/statistics/Statistics';
import Accounting from '../pages/reporting/accounting/Accounting';
import AssemblyMRP from '../pages/reporting/mrp/Assembly';
import MachineMRP from '../pages/reporting/mrp/Machine';
import QualityAssurance from '../pages/reporting/nonconforminglog/QualityAssurance';
import QualityAssuranceCodes from '../pages/management/nonconformingcodes/QualityAssuranceCodes';
import PersonnelLogins from '../pages/reporting/personnel-logins/PersonnelLogins';
import SetupsheetNewTab from '../pages/management/setupsheets/SetupsheetNewTab';
import Maintenance from '../pages/management/maintenance/Maintenance'
import Inventory from '../pages/management/inventory/Inventory';

const Body: FC = () => {
  const [routes, setRoutes] = useState<any[]>([]);

  const components: any = {
    'Dashboard': Dash,
    'Shipping': Shipping,
    'Accounts': Accounts,
    'Factories': Factories,
    'Machines': Machines,
    'Arm Loading': ArmLoading,
    'Molding': Molding,
    'Secondary': SecondaryLineplan,
    'Foaming': FoamingLineplan,
    'Assembly': AssemblyLineplan,
    'Molds': Molds,
    'Items': Items,
    'BOM Items': BomItems,
    'Orders': NewOrders,
    'Personnel': Personnel,
    'Order Sync': OrderSync,
    'Setup Sheets': Setupsheets,
    'Permissions': Permissions,
    'Machine Scheduling': MachineScheduling,
    'Lineplan Scheduling': LineplanScheduling,
    'Production Statistics': Statistics,
    'Machine Planning': MachinePlanning,
    'Line Plans': LinePlan,
    'Assembly MRP': AssemblyMRP,
    'Machine MRP': MachineMRP,
    'Quality Assurance': QualityAssurance,
    'QA Codes': QualityAssuranceCodes,
    'Personnel Statistics': PersonnelLogins,
		'Maintenance': Maintenance, 
		'Inventory': Inventory, 
		'Accounting': Accounting,
  };

  useEffectApi(() => {
    apiGet('/main/pages', {}, (resp: AxiosResponse) => {
      let _routes = [
        { path: '/setupsheet/:itemName/:machineName', component: <SetupsheetNewTab /> }
      ];

      for (const route of resp.data) {
        if (route.page_set && route.page_set.length > 0) {
          for (const subRoute of route.page_set) {
            if (components[subRoute.name]) {
              const Comp = components[subRoute.name];
              const id = subRoute.machine !== null ? subRoute.machine : subRoute.lineplan;
              if (id !== null) {
                _routes.push({ path: subRoute.location, component: <Comp id={id} /> });
                if (subRoute.pre_searchable && subRoute.pre_child_searchable) {
                  _routes.push({ path: `${subRoute.location}/:q/:childQ`, component: <Comp id={id} /> });
                } else if (subRoute.pre_searchable) {
                  _routes.push({ path: `${subRoute.location}/:q`, component: <Comp id={id} /> });
                } else if (subRoute.pre_child_searchable) {
                  _routes.push({ path: `${subRoute.location}/:childQ`, component: <Comp id={id} /> });
                }
              } else {
                _routes.push({ path: subRoute.location, component: <Comp /> });
              }
            } else if (components[route.name]) {
              const Comp = components[route.name];
              const id = subRoute.machine !== null ? subRoute.machine : subRoute.lineplan;
              if (id !== null) {
                _routes.push({ path: subRoute.location, component: <Comp id={id} /> });
              } else {
                _routes.push({ path: subRoute.location, component: <Comp /> });
              }
            }
          }
        } else if (components[route.name]) {
          const Comp = components[route.name];
          const id = route.machine !== null ? route.machine : route.lineplan;
          if (id !== null) {
            _routes.push({ path: route.location, component: <Comp id={id} /> });
          } else {
            _routes.push({ path: route.location, component: <Comp /> });
          }
        }
      }

      setRoutes(_routes);
    });
  }, []);

  return (
    <Box sx={{ maxWidth: 'calc(100vw - 104px)', overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Card sx={{ marginBottom: '.5rem', flexGrow: 1, overflowX: 'auto' }}>
        <Routes>
          {routes.map((route: any, key: number) => <Route path={route.path} element={route.component} key={key} />)}
        </Routes>
      </Card>
      <Copyright />
    </Box>
  );
};

export default Body;
